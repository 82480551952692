import type { FormErrors } from 'redux-form';

export const LOADING_START = 'LOADING_START';
export const LOADING_STOP = 'LOADING_STOP';

/**
 * FormApsideError this error happens when the orbit generated by the inputed
 * parameters has an apside that is out of range. To make the error more useful,
 * the calculated apside is included.
 */
export type FormApsideError = {
  value: 'periapsis_error' | 'apoapsis_error';
  periapsis_altitude?: number;
  apoapsis_altitude?: number;
};

/**
 * Optimizer errors. It is not very clear to us what they do.
 */
export type FormOptimisationMinError = {
  min: string;
};
/**
 * Optimizer errors. It is not very clear to us what they do.
 */
export type FormOptimisationMaxError = {
  max: string;
};

/**
 * Each input can choose how to read and display the errors. Because different
 * inputs work differently, we had to accomodate both options.
 */
export type FormGenericError = string | { value: string };

/**
 * Different error types admitted in the geometry form.
 */
export type FormError =
  | FormGenericError
  | FormOptimisationMinError
  | FormOptimisationMaxError
  | FormApsideError;

/**
 * Errors object for the geometry form.
 */
export type GeometryFormErrors = FormErrors<
  { [key: string]: string },
  FormError
>;

export interface ILoadingState {
  loading: boolean;
}

interface ILoadingStartAction {
  type: typeof LOADING_START;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

interface ILoadingStopAction {
  type: typeof LOADING_STOP;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export type LoadingActionTypes = ILoadingStartAction | ILoadingStopAction;
