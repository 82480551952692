import type { Activity } from '_api/activities/types';
import {
  DetailsItem,
  JSONDetailsItem,
} from 'pages/shared/components/DetailsItem';
import { dateAsDisplayFormat } from 'utils/common/dateUtils';

interface ActivityInfoProps {
  activity: Activity;
}

const ActivityInfo = ({ activity }: ActivityInfoProps) => {
  return (
    <div className="flex flex-col justify-between gap-3 w-full">
      <DetailsItem title="ID: " value={activity.id} applyFlexStyle />
      <DetailsItem title="Status: " value={activity.status} />
      {activity.status_reason && (
        <DetailsItem title="Status Reason: " value={activity.status_reason} />
      )}
      <DetailsItem title="Mission ID: " value={activity.mission_id} />
      <DetailsItem
        title="Imager ID:"
        value={activity.parameters?.imager?.name ?? ''}
      />
      <DetailsItem title="Priority: " value={activity?.priority?.toString()} />
      <DetailsItem
        title="Start Time: "
        value={dateAsDisplayFormat(activity.start_date)}
      />
      <DetailsItem
        title="End Time: "
        value={dateAsDisplayFormat(activity.end_date)}
      />
      <DetailsItem
        title="Roll Angle: "
        value={`${Number(activity.parameters.platform?.roll_angle).toFixed(
          2
        )} deg`}
      />

      <DetailsItem
        title="Observation Zenith Angle:"
        value={`${Number(
          activity.parameters?.physical?.latest?.midpoint.oza_deg
        ).toFixed(2)} deg`}
      />
      <DetailsItem
        title="Sun Zenith Angle: "
        value={`${Number(
          activity.parameters?.physical?.latest?.midpoint.sza_deg
        ).toFixed(2)} deg`}
      />
      <DetailsItem
        title="Cloud coverage: "
        value={`${Number(
          activity.parameters?.metrics?.weather_forecast
            ?.forecast_cloud_cover_pc
        )} %`}
      />

      <DetailsItem
        title="STAC Processing Level: "
        value={
          activity.parameters.stac
            ? // Here I am assuming that processing levels will always be listed in order
              // from least to most processed.
              `${
                activity.parameters.stac[activity.parameters.stac.length - 1]
                  .processing_level
              }(${
                activity.parameters.stac[activity.parameters.stac.length - 1]
                  .stac_link_time_event_processed
              })`
            : 'N/A'
        }
      />

      <JSONDetailsItem
        title="Imager"
        value={activity.parameters.imager ?? {}}
      />

      <JSONDetailsItem
        title="Derived imager"
        value={activity.parameters.derived_imager ?? {}}
      />

      <JSONDetailsItem
        title="Platform"
        value={activity.parameters.platform ?? {}}
      />

      <JSONDetailsItem
        title="Metrics"
        value={activity.parameters.metrics ?? {}}
      />

      <JSONDetailsItem
        title="Ground processing"
        value={activity.parameters.ground_processing ?? {}}
      />

      <JSONDetailsItem
        title="Session"
        value={activity.parameters.session ?? {}}
      />

      <JSONDetailsItem
        title="Onboard Processing"
        value={activity.parameters.onboard_processing ?? {}}
      />

      <JSONDetailsItem title="STAC" value={activity.parameters.stac ?? {}} />
    </div>
  );
};

export default ActivityInfo;
