import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { useMission } from '../../../../services/Missions';
import s from '../../layout.module.scss';
import { Button, OpenappNav, Spinner } from 'opencosmos-ui';
import classNames from 'classnames';
import { useAuth } from 'services/auth/AuthWrapper';
import OrganisationConfiguration from 'components/OrganisationConfiguration';
import { useOpenappNav } from 'pages/shared/context/OpenappNavProvider';

export interface IDefaultLayoutProps {
  children?: ReactNode;
  header?: ReactNode;
  leftHeader?: ReactNode;
  rightHeader?: ReactNode;
  showMissionSelector?: boolean;
  disableMissionSelector?: boolean;
  light?: boolean;
  offLight?: boolean;
  shouldHideClock?: boolean;
  className?: string;
  onClickLogo?: (event: React.MouseEvent<HTMLDivElement>) => void;
  fill?: boolean;
  onMissionClick?: () => void;
  centerContent?: boolean;
}

const DEFAULT_MISSION_NAME = 'Mission name';

// TODO: Find a solution to prevent users to access modules by URL

const DefaultLayout = (props: IDefaultLayoutProps) => {
  const {
    missions,
    currentMission,
    organisations,
    programmes,
    organisationApi,
  } = useMission();

  const { isNavCollapsed, setIsNavCollapsed } = useOpenappNav();

  const { isAllowedToOpenMissionSelector, user } = useAuth();

  const [isOrganizationOpen, setOrganizationOpen] = useState(false);

  const { children, className, fill } = props;

  const containerStyles = [className, s.main];

  if (fill) {
    containerStyles.push(s.main_fullHeightContainer);
  }

  const renderSpinner = (
    <div className={s.spinner}>
      <Spinner size={24} />
    </div>
  );

  return (
    <OpenappNav
      header={{
        left: (
          <div className="flex items-center gap-2">
            {props.showMissionSelector ? (
              <Button
                isMinimal
                isTransparent
                isDisabled={
                  props.disableMissionSelector ??
                  !isAllowedToOpenMissionSelector
                }
                className="p-1 border border-item-contrast dark:border-item-dark-contrast text-left justify-between"
                onPress={() => setOrganizationOpen(true)}
                icon="ChevronDown"
                style={{
                  minWidth: '9rem',
                }}
              >
                <span className="text-sm">
                  {currentMission ? currentMission.name : DEFAULT_MISSION_NAME}
                </span>
              </Button>
            ) : null}
            {props.leftHeader}
          </div>
        ),
        center: props.header,
        right: props.rightHeader,
      }}
      navigationItems={{ footerItems: {}, items: {} }}
      startCollapsed={isNavCollapsed}
      selectedNavigationItemKey={''}
      onToggleCollapse={(isCollapsed) => {
        setIsNavCollapsed(isCollapsed);
      }}
    >
      <div
        className={classNames(
          'mission-page-center',
          'w-full h-full',
          containerStyles.join(' '),
          {
            [s.main_fullHeightContainer]: fill,
            'flex-container': props.centerContent,
          }
        )}
      >
        {props.showMissionSelector && missions.length === 0
          ? renderSpinner
          : children}
      </div>
      {props.showMissionSelector && (
        <OrganisationConfiguration
          user={user}
          isOpen={isOrganizationOpen}
          organisationsList={organisations}
          programmesList={programmes}
          missionsList={missions}
          organisationApi={organisationApi}
          onClose={() => setOrganizationOpen(false)}
          onMissionClick={props.onMissionClick}
        />
      )}
    </OpenappNav>
  );
};

export default DefaultLayout;
