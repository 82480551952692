/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import type { CommandProperties } from '_api/gateway/types';
import {
  get,
  has,
  isUndefined,
  map,
  omitBy,
  pipe,
  reverse,
  sortBy,
  toPairs,
} from 'lodash/fp';

export type OptionalField = {
  title?: string;
  description?: string;
  sortingPreference?: number;
  defaultValue?: string | boolean;
  format?: string;
  enumValue?: string[];
  meta?: string | null;
  media?: { binaryEncoding?: string };
  examples?: string[];
  maxItems?: number;
};

export type PropertyGroup = {
  type: string;
  optionalFields: OptionalField;
  groupPath: string[];
  path?: null | string;
  ocSortingPreference?: number;
  ocMeta?: string | null;
  ocSecret?: boolean;
};

export type IProperty = {
  path: string;
  groupPath: string[];
  type: string;
  optionalFields: OptionalField;
  group: PropertyGroup[];
  required: boolean;
  multiline: boolean;
};

type OptionalFieldsValue = {
  title?: string;
  description?: string;
  format?: string;
  minimum?: number;
  maximum?: number;
  minLength?: number;
  maxLength?: number;
  default?: unknown;
  enum?: unknown[];
  minItems?: number;
  maxItems?: number;
  ocSortingPreference?: number;
  ocMeta?: { value: unknown }[];
  media?: unknown;
  examples?: unknown;
};

const setOptionalFields = (value: OptionalFieldsValue) => ({
  title: value.title,
  description: value.description,
  format: value.format,
  min: value.minimum,
  max: value.maximum,
  minLength: value.minLength,
  maxLength: value.maxLength,
  defaultValue: value.default,
  enumValue: value.enum,
  minItems: value.minItems,
  maxItems: value.maxItems,
  sortingPreference: value.ocSortingPreference,
  meta: value?.ocMeta?.[0].value,
  media: value.media,
  examples: value.examples,
});

const getFieldsBySchema = (
  schema: CommandProperties,
  accPath: string[] = [],
  firstLevelSchema: unknown = null
): IProperty[] => {
  if (!schema) {
    return [];
  }

  const getRequired = (path: string[], key: string) => {
    return get(path.join('.'), firstLevelSchema)?.required?.includes(key);
  };

  return pipe(
    toPairs,
    map(([key, value]) => {
      const { type, ocMultiLine } = value;

      if (value.required) {
        firstLevelSchema = schema;
      }

      // todo is omit necessary?
      const optionalFields = pipe(
        setOptionalFields,
        omitBy(isUndefined)
      )(value);

      const property = {
        type,
        optionalFields,
        groupPath: accPath,
        path: key,
        required: getRequired(accPath.length > 0 ? accPath : [key], key),
        multiline: ocMultiLine as boolean,
      };

      const getFieldForGroupElement = (field: string, acc: string[]) =>
        pipe(
          get(field),
          (item) => getFieldsBySchema(item, acc, firstLevelSchema),
          (group) => ({ group, ...property })
        )(value);

      if (type === 'object') {
        return getFieldForGroupElement('properties', [...accPath, key]);
      }

      if (type === 'array') {
        if (has('properties', value)) {
          return getFieldForGroupElement('properties', [...accPath, key]);
        }

        return {
          ...property,
          group: [{ ...property, ...value.items, path: null }],
        };
      }

      return property;
    }),
    sortBy('optionalFields.sortingPreference'),
    reverse
  )(schema) as unknown as IProperty[];
};

export default getFieldsBySchema;
