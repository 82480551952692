import React from 'react';
import { find } from 'lodash/fp';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ModuleBall from 'components/missionDesignPage/ModuleBall';
import type { ICurve, ISVGPoint } from 'constants/missionDesignPage/interfaces';
import { initialSatelliteBall } from 'constants/missionDesignPage/constants';
import { getQuadraticBezierCurves } from 'utils/missionDesignPage/getQuadraticBezierCurves';
import { MissionPageHeader } from 'components/missionDesignPage/header/MissionPageHeader';
import { computeBallsState } from 'utils/missionDesignPage/computeBallsStates';
import MissionDesignBottom from 'components/missionDesignPage/bottom/MissionDesignBottom';
import type { ISatelliteBall } from 'constants/satelliteBalls/actionTypes';
import { BALL_STATES } from 'constants/satelliteBalls/constants';
import { connect } from 'react-redux';
import {
  addGroupSB,
  removeGroupSB,
  updateGroupSB,
} from 'pages/shared/state/actions/groupSatelliteBalls/thunks';
import type { IGroup } from 'constants/groupSatelliteBalls/actionTypes';
import type { AppState } from 'store';
import type { IUserState } from 'constants/user/actionTypes';
import type { IMission } from 'services/Missions';
import { withMission } from 'services/Missions';
import type { ThunkDispatch } from 'redux-thunk';
import type { Action } from 'redux';
import {
  getClearLocation,
  getCurrentMissionPath,
  handleOnBallClick,
  modifySatelliteBallByState,
} from 'utils/missionDesignPage/common';
import { removeExcessSlash } from 'utils/common/stringUtils';
import { GRAFANA_DASHBOARD } from 'constants/externalUrls';
import { history } from 'config';
import { useAuth } from 'services/auth/AuthWrapper';
import type { ICheckPermissions } from 'services/auth/useAuthorisation';
import {
  DOCS_ENABLE,
  MISSION_DESIGN_ENABLE,
  OPERATIONS_ENABLE,
  TASKING_ENABLE,
  TASKING_OVERVIEW_ENABLE,
} from 'env';

interface IStateProps {
  groups: IGroup[];
  user: IUserState | null;
  missions: IMission[];
  location?: Location;
}

interface IDispatchProps {
  addGroupSB: (group: IGroup) => void;
  updateGroupSB: (group: IGroup) => void;
  removeGroupSB: (group: IGroup) => void;
  updateResultsThunk: (path: string) => void;
}

interface IState {
  curve: ICurve;
  chosenRoute: string;
}

interface WithMissionHOC {
  currentMissionId: number;
  currentMission?: IMission;
}

export type IProps = IStateProps &
  IDispatchProps &
  WithTranslation &
  WithMissionHOC & {
    checkPermissions: {
      (perms: ICheckPermissions[]): Promise<boolean[]>;
      (perms: ICheckPermissions): Promise<boolean>;
      (perms: ICheckPermissions[] | ICheckPermissions): Promise<
        boolean | boolean[]
      >;
    };
  };

class MissionDesignPage extends React.Component<IProps, IState> {
  public state: IState = {
    curve: {
      ref: React.createRef<SVGSVGElement>(),
      strokeColor: '#073763',
      path: '',
      padding: 10,
      points: undefined,
      selected: false,
    },
    chosenRoute: getClearLocation(this.props.location, '/mission'),
  };
  public componentDidMount(): void {
    window.addEventListener('resize', this.recalculateCurve);
    this.recalculateCurve();
    const { t, groups } = this.props;
    if (groups.length) {
      return undefined;
    }
    const newGroups: IGroup[] = [
      ...this.missionDesign(),
      ...this.operations(),
      {
        label: t('module_data.module_name'),
        route: '/data',
        satelliteBalls: this.dataCosmosBalls(),
      },
    ];
    const recomputeGroup = newGroups.map((g) => {
      return {
        ...g,
        satelliteBalls: computeBallsState(g.satelliteBalls),
      };
    });

    recomputeGroup.forEach((g) => this.props.addGroupSB(g));
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.recalculateCurve);
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    const groupToShowHasChanged = this.state.curve !== prevState.curve;
    const isUser = this.props.user !== prevProps.user;
    const isMission = this.props.currentMission !== prevProps.currentMission;

    if (groupToShowHasChanged || isUser || isMission) {
      void this.recalculateTheSatelliteBall();
    }
  }

  /**
   * @returns the mission design page top level "group", if enabled
   */
  private missionDesign(): IGroup[] {
    if (!MISSION_DESIGN_ENABLE) {
      return [];
    }
    const { t } = this.props;
    return [
      {
        label: t('module_msd.module_name'),
        route: '/msd',
        satelliteBalls: [
          { ...initialSatelliteBall, scopes: 'msd' },
          { ...initialSatelliteBall, scopes: 'msd' },
          { ...initialSatelliteBall, scopes: 'msd' },
          { ...initialSatelliteBall, scopes: 'msd' },
          { ...initialSatelliteBall, scopes: 'msd' },
        ],
      },
    ];
  }

  /**
   * @returns the operations page top level group, if configured
   */
  private operations(): IGroup[] {
    if (!OPERATIONS_ENABLE) {
      return [];
    }
    const { t } = this.props;
    return [
      {
        label: t('module_ops.module_name'),
        route: '/ops',
        missionRequired: true,
        satelliteBalls: [
          {
            ...initialSatelliteBall,
            icon: '/images/icons/Telemetry_Icon.svg',
            text: t('module_ops.telemetry'),
            alt: t('module_ops.telemetry'),
            dependsOf: [],
            redirectFn: GRAFANA_DASHBOARD,
            scopes: 'ops:tm:grafana:user',
            handleOnClick: handleOnBallClick,
            isDisabled: (props) => !props.currentMissionId,
            permissionType: 'mission',
          },
          {
            ...initialSatelliteBall,
            icon: '/images/icons/Library_Icon.svg',
            text: t('module_ops.library'),
            alt: t('module_ops.library'),
            dependsOf: [],
            redirectFn: (missionId) => `/ops/mission/${missionId}/library`,
            scopes: 'ops:satellitegateway:configuration:read',
            permissionType: 'mission',
            isDisabled: (props) => !props.currentMissionId,
            handleOnClick: handleOnBallClick,
          },
          {
            ...initialSatelliteBall,
            icon: '/images/icons/Scheduler_Icon.svg',
            text: t('module_ops.gss'),
            alt: t('module_ops.gss'),
            dependsOf: [],
            scopes: 'portal:gs:mission:read',
            permissionType: 'mission',
            isDisabled: (props) => !props.currentMissionId,
            redirectFn: (missionId) =>
              `/ops/mission/${missionId}/gs-scheduling`,
            handleOnClick: handleOnBallClick,
          },
          {
            ...initialSatelliteBall,
            icon: '/images/icons/Calendar.svg',
            text: t('module_ops.scheduling'),
            alt: t('module_ops.scheduling'),
            dependsOf: [],
            scopes: 'ops:activity:read',
            permissionType: 'mission',
            isDisabled: (props) => !props.currentMissionId,
            redirectFn: (missionId) => `/ops/mission/${missionId}/schedule`,
            handleOnClick: handleOnBallClick,
          },
          {
            ...initialSatelliteBall,
            icon: '/images/icons/Real_Time_Icon.svg',
            text: t('module_ops.rti'),
            alt: t('module_ops.rti'),
            dependsOf: [],
            redirectFn: (missionId) => `/ops/mission/${missionId}/rti`,
            scopes: 'ops:rti:session:read',
            permissionType: 'mission',
            isDisabled: (props) => !props.currentMissionId,
            handleOnClick: handleOnBallClick,
          },
          {
            ...initialSatelliteBall,
            icon: '/images/icons/Scripting.png',
            text: t('module_ops.scripting'),
            alt: t('module_ops.scripting'),
            dependsOf: [],
            redirectFn: (missionId) => `/ops/mission/${missionId}/scripting`,
            scopes: 'ops:scripting:read',
            permissionType: 'mission',
            isDisabled: (props) => !props.currentMissionId,
            handleOnClick: handleOnBallClick,
          },
          {
            ...initialSatelliteBall,
            icon: '/images/icons/Payload_Icon.svg',
            text: t('module_pdgs.processing'),
            alt: t('module_pdgs.processing'),
            dependsOf: [],
            redirectFn: (missionId) =>
              `/ops/mission/${missionId}/pdgs/image-processing`,
            scopes: 'ops:pdgs:workflows:read',
            isDisabled: (props) => !props.currentMissionId,
            handleOnClick: handleOnBallClick,
            permissionType: 'mission',
          },
        ],
      },
    ];
  }

  /**
   * @returns all balls for the top level/main DataCosmos page
   */
  private dataCosmosBalls(): ISatelliteBall[] {
    const { t } = this.props;
    return [
      {
        ...initialSatelliteBall,
        icon: '/images/icons/Search_Icon.svg',
        text: t('module_data.search'),
        alt: t('module_data.search'),
        dependsOf: [],
        redirectPath: '/data/project/catalog',
        scopes: 'data:feature',
        handleOnClick: (_props, ball) => ({
          type: 'redirect',
          value: ball.redirectPath,
        }),
      },
      {
        ...initialSatelliteBall,
        icon: '/images/icons/Map_Icon.svg',
        text: t('module_data.scenario'),
        alt: t('module_data.scenario'),
        dependsOf: [],
        redirectPath: '/data/project/items',
        scopes: 'data:feature',
        handleOnClick: (_props, ball) => ({
          type: 'redirect',
          value: ball.redirectPath,
        }),
      },
      ...this.taskingBall(),
      {
        ...initialSatelliteBall,
        icon: '/images/icons/App_Icon.svg',
        text: t('module_data.application'),
        alt: t('module_data.application'),
        dependsOf: [],
        redirectPath: '/data/project/application',
        scopes: 'data:processing:read',
        handleOnClick: (_props, ball) => ({
          type: 'redirect',
          value: ball.redirectPath,
        }),
      },
      ...this.docsBall(),
      {
        ...initialSatelliteBall,
        icon: '/images/icons/Views_Icon.svg',
        text: t('module_data.views'),
        alt: t('module_data.views'),
        dependsOf: [],
        redirectPath: '/data/views/',
        scopes: 'data:view:read:own',
        handleOnClick: (_props, ball) => ({
          type: 'redirect',
          value: ball.redirectPath,
        }),
      },
      ...this.taskingOverviewBall(),
    ];
  }

  /**
   * @returns the tasking ball if configured, else nothing
   */
  private taskingBall(): ISatelliteBall[] {
    if (!TASKING_ENABLE) {
      return [];
    }
    const { t } = this.props;
    return [
      {
        ...initialSatelliteBall,
        icon: '/images/icons/Satellite_Icon.svg',
        text: t('module_data.tasking'),
        alt: t('module_data.tasking'),
        dependsOf: [],
        redirectPath: '/data/project/tasking',
        scopes: 'data:tasking:search',
        handleOnClick: (_props, ball) => ({
          type: 'redirect',
          value: ball.redirectPath,
        }),
      },
    ];
  }

  /**
   * @returns the docs ball if configured, else nothing
   */
  private docsBall(): ISatelliteBall[] {
    if (!DOCS_ENABLE) {
      return [];
    }
    const { t } = this.props;
    return [
      {
        ...initialSatelliteBall,
        icon: '/images/icons/Library_Icon.svg',
        text: t('module_data.docs'),
        alt: t('module_data.docs'),
        dependsOf: [],
        redirectPath: '/help',
        scopes: 'data:docs',
        handleOnClick: (_props, ball) => ({
          type: 'external_redirect',
          value: ball.redirectPath,
        }),
      },
    ];
  }

  /**
   * @returns the tasking overview ball if configured, else nothing
   */
  private taskingOverviewBall(): ISatelliteBall[] {
    if (!TASKING_OVERVIEW_ENABLE) {
      return [];
    }
    const { t } = this.props;
    return [
      {
        ...initialSatelliteBall,
        icon: '/images/icons/Calendar.svg',
        text: t('module_data.tasking_overview'),
        alt: t('module_data.tasking_overview'),
        dependsOf: [],
        redirectPath: '/data/tasking/overview',
        scopes: 'data:tasking:request:read:own',
        handleOnClick: (_props, ball) => ({
          type: 'redirect',
          value: ball.redirectPath,
        }),
      },
    ];
  }

  private recalculateTheSatelliteBall = async () => {
    if (this.state.curve.points === undefined) {
      return;
    }

    const { first, control, last } = this.state.curve.points;
    const newGroups = await Promise.all(
      this.props.groups.map(async (g) => {
        const { satelliteBalls } = g;
        const divideOn = satelliteBalls.length + 1;
        g.redirectPath = g.missionRequired
          ? getCurrentMissionPath(this.props.missions, g.route)
          : undefined;
        g.satelliteBalls = satelliteBalls.map((s, index): ISatelliteBall => {
          const t = (1 / divideOn) * (index + 1);
          const { x: left, y: top } = getQuadraticBezierCurves(
            first,
            control,
            last,
            t
          );
          const state = s.compareFunction
            ? s.compareFunction(this.props)
            : s.state;
          const satelliteBall: ISatelliteBall = {
            ...s,
            position: {
              left: `calc(${left}vw)`,
              top: `calc(${top}vh)`,
            },
            state,
          };
          // TODO: Fix before using
          // computeTremblingAnimation(0.1, 5, satelliteBall);
          return satelliteBall;
        });

        const permissionsToCheck = g.satelliteBalls.map((ball) => {
          const { user } = this.props;
          if (!user) {
            return { ...ball, state: BALL_STATES.DISABLE };
          }

          const missionDefined = this.props.currentMission?.mission;
          const ballRequiresMissionPermission =
            ball.permissionType === 'mission';
          let resourceID;
          if (ballRequiresMissionPermission && missionDefined) {
            resourceID = this.props.currentMission?.mission;
          }

          return {
            actionScope: ball.scopes,
            type: ball.permissionType,
            id: resourceID,
          } as ICheckPermissions;
        });

        const permissions = await this.props.checkPermissions(
          permissionsToCheck as ICheckPermissions[]
        );

        g.satelliteBalls = computeBallsState(g.satelliteBalls).map(
          (ball, index) => {
            if (!permissions[index] || ball.isDisabled?.(this.props)) {
              return { ...ball, state: BALL_STATES.DISABLE };
            }
            return { ...ball };
          }
        );

        return g;
      })
    );
    newGroups.forEach((g) => this.props.updateGroupSB(g));
  };

  private recalculateCurve = (): void => {
    const { curve } = this.state;
    const firstPoint: ISVGPoint = {
      x: 0,
      y: 60,
    };
    const lastPoint: ISVGPoint = {
      x: 100,
      y: 60,
    };
    const controlPoint: ISVGPoint = {
      x: 50,
      y: 10,
    };

    const path = `M${firstPoint.x} ${firstPoint.y} Q ${controlPoint.x} ${controlPoint.y} ${lastPoint.x} ${lastPoint.y}`;
    const points = {
      first: firstPoint,
      last: lastPoint,
      control: controlPoint,
    };
    this.setState({ curve: { ...curve, path, points } });
  };

  private handleRouteChange = (redirectPath: string, isExternal?: boolean) => {
    if (this.props.location === undefined) {
      return;
    }

    const { pathname } = this.props.location;
    if (removeExcessSlash(redirectPath) === removeExcessSlash(pathname)) {
      return;
    }

    if (isExternal) {
      window.location.href = redirectPath;
    } else {
      history.push(redirectPath);
    }
  };

  private handleChangeStateBall = (id: string) => {
    const group = find(['route', this.state.chosenRoute], this.props.groups);

    if (group === undefined) {
      return;
    }

    const satelliteBalls = group.satelliteBalls.map(
      modifySatelliteBallByState(id, this.props, this.handleRouteChange)
    );

    this.props.updateGroupSB({
      ...group,
      satelliteBalls: computeBallsState(satelliteBalls as ISatelliteBall[]),
    });
  };

  private chooseGroupByRoute = () => {
    let group = find(['route', this.state.chosenRoute], this.props.groups);
    if (!group) group = find(['route', '/msd'], this.props.groups);
    return group;
  };

  public render() {
    const { groups } = this.props;
    const { curve, chosenRoute } = this.state;

    const group = this.chooseGroupByRoute();

    const bottomGroups = groups.map((g) => {
      return {
        ...g,
        satelliteBalls: g.satelliteBalls.map((s) => {
          return {
            ...s,
            size: { width: 20, height: 20 },
          };
        }),
      };
    });

    return (
      <div className="mission-page">
        <MissionPageHeader showMissionSelector={group?.missionRequired} />
        <div className="orbit-curve">
          {group?.satelliteBalls.map((s) => {
            if (s.redirectPath === '/data/project/catalog') {
              return (
                <ModuleBall
                  key={`satellite_ball${s.text}`}
                  handleChangeState={this.handleChangeStateBall}
                  ballsState={group.satelliteBalls}
                  {...s}
                />
              );
            }
            return (
              <ModuleBall
                key={`satellite_ball${s.text}`}
                handleChangeState={this.handleChangeStateBall}
                ballsState={group.satelliteBalls}
                {...s}
              />
            );
          })}
          <svg
            ref={curve.ref}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d={curve.path}
              stroke={curve.strokeColor}
              opacity={0}
              strokeWidth={3}
              fill="transparent"
            />
          </svg>
        </div>
        {groups.length && (
          <MissionDesignBottom
            chosenRoute={chosenRoute}
            groups={bottomGroups}
            handleRouteChange={this.handleRouteChange}
          />
        )}
      </div>
    );
  }
}

const ConnectedComponent = connect<IStateProps, IDispatchProps>(
  //@ts-expect-error
  (state: AppState): IStateProps => ({
    groups: state.groups,
    user: state.user,
    missions: state.missions,
  }),
  (
    dispatch: ThunkDispatch<AppState, null, Action<string>>
  ): IDispatchProps => ({
    addGroupSB: (group) => dispatch(addGroupSB(group)),
    updateGroupSB: (group) => dispatch(updateGroupSB(group)),
    removeGroupSB: (group) => dispatch(removeGroupSB(group)),
    updateResultsThunk: () => {},
  })
)(withMission(withTranslation()(MissionDesignPage)));

const HookConnector = (props: IProps) => {
  return (
    // @ts-expect-error
    <ConnectedComponent
      {...{ ...props, checkPermissions: useAuth().checkPermissions }}
    />
  );
};

export default HookConnector;
