import NotFoundPage from 'pages/shared/NotFoundPage';
import { LoginPage } from 'pages/shared/LoginPage';
import LogoutPage from 'pages/shared/LogoutPage';
import MissionDesignPage from 'pages/shared/MissionDesignPage';
import BasePage from 'pages/shared/BasePage';
import ROUTES from 'routeConstants';
import OrganisationAdministration from 'pages/portal/Administration/Organisation/OrganisationAdministration';
import ProgrammeAdministration from 'pages/portal/Administration/Programme/ProgrammeAdministration';
import RolesAdministration from 'pages/portal/Administration/Roles/RolesAdministration';
import UserAdministration from 'pages/portal/Administration/UserAdministration';
import { HomeWrapper } from 'datacosmos';
import UserPage from 'pages/portal/Administration/User/UserPage';
import { ViewsScreen } from 'pages/datacosmos/views/ViewsScreen';
import TaskingOverviewScreen from 'pages/datacosmos/tasking_overview/TaskingOverviewScreen';
import OrdersScreen from 'pages/datacosmos/orders/OrdersScreen';
import AuthErrorPage from 'pages/shared/AuthErrorPage';
import GenericResourcePage from 'pages/datacosmos/GenericResourcePage';
import type {
  PermissionScope,
  PermissionType,
} from '_api/administration/permissions';
import type { ComponentType } from 'react';
import OrganisationsList from 'pages/portal/Administration/Organisation/OrganisationsList';

export type RouteType = {
  path?: string;
  auth: PermissionScope[];
  permissionType?: PermissionType;
  isPrivate?: boolean;
  exact?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ComponentType<any>;
};

const navigateToMsd = (path: string) => {
  if (window.location.host.includes('localhost')) {
    window.location.href = `http://localhost:3003${path}`;
    return null;
  }
  window.location.href = path;
  return null;
};

const navigateToOps = () => {
  if (window.location.host.includes('localhost')) {
    window.location.href = `http://localhost:3004${window.location.pathname}${window.location.search}`;
    return null;
  }
  window.location.href = `${window.location.pathname}${window.location.search}`;
  return null;
};

// Define app routes here.
// Each route should have a path, a component, and an array of auth strings.
// The auth strings are used to check if the user has the required permissions to access the route.
// If the route is set to private, user must have proper permissions
export const routes: RouteType[] = [
  {
    path: '/login',
    auth: [],
    exact: true,
    component: LoginPage,
  },
  {
    path: '/',
    auth: [],
    exact: true,
    component: BasePage,
  },
  {
    path: '/ops',
    auth: [],
    exact: true,
    component: () => navigateToOps(),
  },
  {
    path: '/data',
    auth: [],
    exact: true,
    component: MissionDesignPage,
  },
  {
    path: '/msd/payload',
    auth: ['msd'],
    exact: true,
    component: () => {
      return navigateToMsd('/msd/payload');
    },
  },
  {
    path: '/ops/mission/:mission',
    auth: ['mission:read'],
    isPrivate: true,
    exact: true,
    component: () => navigateToOps(),
    permissionType: 'mission',
  },
  {
    path: ROUTES.MSD,
    auth: ['msd'],
    exact: true,
    isPrivate: true,
    component: () => {
      return navigateToMsd(ROUTES.MSD);
    },
  },
  {
    path: '/msd/mission-geometry',
    auth: ['msd'],
    exact: true,
    component: () => {
      return navigateToMsd('/msd/mission-geometry');
    },
  },
  {
    path: '/msd/ground-segment',
    auth: ['msd'],
    exact: true,
    component: () => {
      return navigateToMsd('/msd/ground-segment');
    },
  },
  {
    path: '/msd/platform',
    auth: ['msd'],
    exact: true,
    component: () => {
      return navigateToMsd('/msd/platform');
    },
  },
  {
    path: ROUTES.FINAL_PAGE,
    auth: ['msd'],
    exact: true,
    component: () => {
      return navigateToMsd(ROUTES.FINAL_PAGE);
    },
  },
  {
    path: '/loggedin',
    auth: [],
    exact: true,
    component: BasePage,
  },
  {
    path: '/loggedout',
    auth: [],
    exact: true,
    component: LogoutPage,
  },
  {
    path: '/error',
    auth: [],
    exact: true,
    component: AuthErrorPage,
  },
  {
    path: '/ops/mission/:mission/rti',
    auth: ['ops:rti:session:read'],
    permissionType: 'mission',
    exact: true,
    component: () => navigateToOps(),
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/rti/session/:session',
    auth: ['ops:rti:session:read'],
    permissionType: 'mission',
    exact: true,
    component: () => navigateToOps(),
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/gs-scheduling',
    auth: ['portal:gs:mission:read'],
    permissionType: 'mission',
    exact: false,
    component: () => navigateToOps(),
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/library',
    auth: ['ops:satellitegateway:configuration:read'],
    permissionType: 'mission',
    exact: false,
    component: () => navigateToOps(),
    isPrivate: true,
  },
  {
    path: '/ops/telemetry',
    auth: ['ops:tm:grafana:user'],
    exact: false,
    component: () => navigateToOps(),
    permissionType: 'mission',
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/scripting',
    auth: ['ops:scripting:read'],
    permissionType: 'mission',
    exact: false,
    component: () => navigateToOps(),
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/scripts',
    auth: ['ops:scripting:read'],
    permissionType: 'mission',
    exact: false,
    component: () => navigateToOps(),
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/runs',
    auth: ['ops:scripting:read'],
    permissionType: 'mission',
    exact: false,
    component: () => navigateToOps(),
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/gse',
    auth: ['ops:scripting:read'],
    permissionType: 'mission',
    exact: false,
    component: () => navigateToOps(),
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/schedule',
    auth: ['ops:activity:read'],
    permissionType: 'mission',
    exact: false,
    component: () => navigateToOps(),
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/schedule/activity/:activity',
    auth: ['ops:activity:read'],
    permissionType: 'mission',
    exact: false,
    component: () => navigateToOps(),
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/pdgs/image-processing',
    auth: ['ops:pdgs:workflows:read'],
    permissionType: 'mission',
    exact: false,
    component: () => navigateToOps(),
    isPrivate: true,
  },
  {
    path: '/portal/organisations',
    auth: ['organisation:assignment:read'],
    permissionType: 'organisation',
    exact: true,
    component: OrganisationsList,
    isPrivate: true,
  },
  {
    path: '/portal/organisation/:organisation',
    auth: ['organisation:assignment:read'],
    permissionType: 'organisation',
    exact: false,
    component: OrganisationAdministration,
    isPrivate: true,
  },
  {
    path: '/portal/programme/:programme',
    auth: ['programme:assignment:read'],
    permissionType: 'programme',
    exact: false,
    component: ProgrammeAdministration,
    isPrivate: true,
  },
  {
    path: '/portal/roles',
    auth: ['role:read'],
    exact: false,
    component: RolesAdministration,
    isPrivate: true,
  },
  {
    path: '/portal/users',
    auth: ['user:assignment:write'],
    exact: true,
    component: UserAdministration,
    isPrivate: true,
  },
  {
    path: '/portal/users/:user_subject',
    auth: ['role:read'],
    component: UserPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/data/map',
    auth: ['data:feature'],
    exact: false,
    component: HomeWrapper,
    isPrivate: true,
  },
  {
    path: '/data/project',
    auth: ['data:scenario:read:own', 'data:tasking:search'],
    exact: false,
    component: HomeWrapper,
    isPrivate: true,
  },
  {
    path: '/data/views',
    auth: ['data:view:read:own'],
    exact: false,
    component: ViewsScreen,
    permissionType: 'datacosmos_view',
    isPrivate: true,
  },
  {
    path: '/data/tasking/overview',
    auth: ['data:tasking:request:read:own'],
    exact: false,
    component: TaskingOverviewScreen,
    permissionType: 'mission',
    isPrivate: true,
  },
  {
    path: '/data/orders',
    auth: [],
    exact: false,
    component: OrdersScreen,
    isPrivate: true,
  },
  {
    path: '/resource/:resource_type/:resource_id',
    auth: [],
    exact: false,
    component: GenericResourcePage,
    isPrivate: true,
  },
  {
    auth: [],
    component: NotFoundPage,
  },
];
