import type { SwathLayer } from 'datacosmos/entities/SwathLayer';
import type { CommonOpportunity } from '_api/tasking/helpers';
import moment from 'moment';
import area from '@turf/area';
import { ALL_SATELLITES } from '_api/satellites/constants';
import { useMemo } from 'react';
import { Icon } from 'opencosmos-ui';
import type { CloudCoverConstraint } from '_api/tasking/types';
import { dateAsDisplayFormat } from 'utils/common/dateUtils';

type ConfirmedSwathCardProps = {
  confirmedSwath: SwathLayer<CommonOpportunity>;
  cloudConstraint: CloudCoverConstraint;
};

const calculateSeconds = (opportunity: SwathLayer<CommonOpportunity>) => {
  const min = Math.floor(opportunity.metadata.Duration / 60.0);
  const sec = Math.round(opportunity.metadata.Duration % 60);
  let duration = `${sec}s`;
  if (min > 0) {
    duration = `${min}m ${duration}`;
  }
  return duration;
};

export const ConfirmedSwathCard = ({
  confirmedSwath,
  cloudConstraint,
}: ConfirmedSwathCardProps) => {
  const duration = useMemo(
    () => calculateSeconds(confirmedSwath),
    [confirmedSwath]
  );
  return (
    <div className="w-full flex flex-col gap-2 group bg-item px-2 dark:bg-item-dark dark:text-item-dark-contrast">
      <div className="flex w-full gap-16 mb-2">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <span>#{confirmedSwath.metadata.OpportunityIndex}</span>
            <span data-testid="date-from">
              {dateAsDisplayFormat(confirmedSwath.metadata.Start)}
            </span>
          </div>
          <span className="flex items-center gap-2">
            <Icon icon="Clock" />
            {duration}
          </span>
          <span className="flex items-center gap-2">
            <Icon icon="Satellite" />
            {ALL_SATELLITES.find(
              (s) => s.satellite === confirmedSwath.metadata.SatelliteId
            )?.uiName ?? confirmedSwath.metadata.SatelliteId}
          </span>

          <span data-testid="sensor-id" className="flex items-center gap-2">
            <Icon icon="SensorType" />
            {confirmedSwath.metadata.SensorId}
          </span>

          <div data-testid="cloud" className="flex items-center gap-2">
            <Icon icon="Cloud" />
            {cloudConstraint.min} - {cloudConstraint.max}%
          </div>
        </div>

        <div className="flex flex-col gap-2">
          {confirmedSwath.metadata.Oza ? (
            <div className="flex items-center gap-2">
              <Icon icon="Angle" />
              <span>{confirmedSwath.metadata.Oza.toFixed(2)}</span>
            </div>
          ) : null}

          {confirmedSwath.metadata.Sza ? (
            <div className="flex items-center gap-2">
              <Icon icon="SunAngle" />
              <span>{confirmedSwath.metadata.Sza.toFixed(2)}</span>
            </div>
          ) : null}

          <span data-testid="roll-angle" className="flex items-center gap-2">
            <Icon icon="SatelliteRollAngle" />
            {Number(confirmedSwath.metadata.RollAngle).toFixed(1)}°
          </span>

          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2">
              <Icon icon="Area" className="dark:invert" />
              <span>
                {confirmedSwath.metadata.Area
                  ? confirmedSwath.metadata.Area.toFixed(2)
                  : Number(
                      area(confirmedSwath.data as GeoJSON.Feature) / 1000000
                    ).toFixed(2)}{' '}
                km²
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
