import type { Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import UploadRegion from 'datacosmos/components/UploadRegion';
import area from '@turf/area';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';
import { TaskingAoiCard } from 'datacosmos/components/Tasking/AreaOfInterest/TaskingAoiCard';
import type { IMapContext } from 'datacosmos/stores/MapProvider';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Button, Tooltip } from 'opencosmos-ui';
import { toaster } from 'toaster';
import { useMemo } from 'react';

type AreaOfInterestProps = {
  regions: PolygonLayer[];
  replaceLayer: (layer: Layer) => void;
  removeLayer: (layerid: string) => void;
  addLayer: (...newLayers: Layer[]) => void;
  drawPolygon: IMapContext['drawPolygon'];
  editPolygon: IMapContext['editPolygon'];
  disableEditing: IMapContext['disableEditing'];
  isFetching: boolean;
  centerRegion: (region: PolygonLayer) => void;
  isRegionValid: boolean;
  disabled: boolean;
  removeLayersBySourceType: (type: LayerSourceType) => void;
};

export const AreaOfInterest = ({
  regions,
  isFetching,
  replaceLayer,
  addLayer,
  drawPolygon,
  removeLayer,
  editPolygon,
  disableEditing,
  centerRegion,
  isRegionValid,
  disabled,
  removeLayersBySourceType,
}: AreaOfInterestProps) => {
  const { translate } = useLocalisation();

  const disableButtons = useMemo(
    () => isFetching || regions.length > 0,
    [isFetching, regions.length]
  );

  const triggerDrawPolygon = () => {
    drawPolygon()
      .then((res) => {
        const m2 = area(res.polygon);
        addLayer(
          PolygonLayerFactory(
            LayerSourceType.TASKING_REGIONS,
            translate('datacosmos.layers.names.region'),
            res.polygon,
            m2,
            res.polygonMetadata,
            { color: '#0000ff' }
          )
        );
      })
      .catch((err) => {
        toaster.show({
          icon: 'delete',
          intent: 'danger',
          message: err,
        });
      });
  };
  return (
    <div className="w-full color-item mb-2">
      <div className="flex w-full items-center h-8">
        <span className="whitespace-nowrap mr-4 ml-2 font-bold">
          {translate('datacosmos.tasking.new.aoi')}
        </span>

        <Tooltip
          content={translate('datacosmos.tooltips.tasking.noMultiple')}
          isDisabled={!disableButtons}
          className="flex-1 flex"
        >
          <div className="flex-1 flex justify-evenly">
            <Button
              icon="AoiAdd"
              size={'lg'}
              iconPlacement="left"
              isMinimal
              isTransparent
              isDisabled={disableButtons}
              onPress={() => triggerDrawPolygon()}
              className={'text-sm gap-2'}
            >
              Draw
            </Button>
            <UploadRegion
              aoiSourceType={LayerSourceType.TASKING_REGIONS}
              setAreaOfInterest={() => {}}
              buttonTitle={translate('datacosmos.tasking.new.upload')}
              buttonForTasking
              disableButton={disableButtons}
              onAddLayer={(polyLayer) => centerRegion(polyLayer)}
            />
          </div>
        </Tooltip>
      </div>

      {regions.length > 0 ? (
        <div className="flex flex-col max-h-40 overflow-y-auto">
          {regions.map((region: PolygonLayer, index: number) => {
            return (
              <TaskingAoiCard
                disabled={disabled}
                key={region.id}
                region={region}
                replaceLayer={replaceLayer}
                disableEditing={disableEditing}
                editPolygon={editPolygon}
                removeLayer={removeLayer}
                centerRegion={centerRegion}
                isRegionValid={isRegionValid}
                removeLayersBySourceType={removeLayersBySourceType}
                regionIndex={index}
              />
            );
          })}

          <div className="px-4 my-2">
            <Button
              icon="Plus"
              size={'sm'}
              onPress={() => triggerDrawPolygon()}
              fill
            >
              Draw AOI
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
