import { useState } from 'react';
import { getBillingPortalURL } from '_api/orders/service';
import {
  Button,
  Input,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectValue,
  TextField,
  Spinner,
} from 'opencosmos-ui';
import type {
  Organisation,
  OrganisationJoinPolicy,
} from '_api/administration/types';
import { updateOrganisation } from '_api/organisations/service';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  organisation?: Organisation;
  disableBilling: boolean;
  refetchOrganisation: () => void;
}

const AdministrationOrganisationDetails = (props: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { translate } = useLocalisation();

  const handleManageBillingClick = async () => {
    if (!props.organisation) {
      return;
    }
    setLoading(true);
    const { data } = await getBillingPortalURL({
      params: {
        organisationId: props?.organisation.id.toString(),
      },
    });
    if (data?.portal_link) {
      window.open(data?.portal_link, '_blank');
    }
    setLoading(false);
  };

  return (
    <div>
      <h2 className="text-lg mt-6">{translate('organisationsPage.details')}</h2>
      <div className="pl-2">
        <h3 className="font-bold mt-4">
          {translate('organisationsPage.discoverability')}
        </h3>
        <Select
          className="mt-2 w-full"
          label={translate('organisationsPage.joinPolicy')}
          description={translate('organisationsPage.joinPolicyDescription')}
          selectedKey={props.organisation?.join_policy}
          fill
          onSelectionChange={async (policy) => {
            const res = await updateOrganisation({
              params: {
                id: props.organisation?.id.toString()!,
              },
              body: {
                join_policy: policy as unknown as OrganisationJoinPolicy,
              },
            });

            if (res.status === 200) {
              props.refetchOrganisation();
            }
          }}
        >
          <Button icon="ChevronDown" iconPlacement="right" className="w-full">
            <SelectValue />
          </Button>
          <Popover
            className="shadow-lg bg-neutral-100 overflow-auto"
            maxHeight={250}
          >
            <ListBox>
              <ListBoxItem id="JOIN">
                {translate(
                  'onboarding.createOrganisation.form.fields.howToJoin.options.join'
                )}
              </ListBoxItem>
              <ListBoxItem id="REQUEST">
                {translate(
                  'onboarding.createOrganisation.form.fields.howToJoin.options.requestToJoin'
                )}
              </ListBoxItem>
              <ListBoxItem id="INVITE">
                {translate(
                  'onboarding.createOrganisation.form.fields.howToJoin.options.onInvite'
                )}
              </ListBoxItem>
            </ListBox>
          </Popover>
        </Select>
        <TextField
          className="mt-2"
          label={translate('organisationsPage.domain')}
          value={props.organisation?.domain}
          isDisabled
        >
          <Input type="text" />
        </TextField>

        <h3 className="font-bold mt-4">
          {translate('organisationsPage.billing')}
        </h3>
        <p className="text-sm">
          {translate('organisationsPage.billingDescription')}
        </p>
        <Button
          intent="secondary"
          icon="DollarSign"
          iconPlacement="left"
          size="sm"
          className="mt-2"
          onPress={handleManageBillingClick}
          isDisabled={loading && !props.disableBilling}
        >
          {translate('organisationsPage.goToBilling')}
          {loading && <Spinner size={'sm'} />}
        </Button>
      </div>
    </div>
  );
};

export default AdministrationOrganisationDetails;
