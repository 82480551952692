import { useEffect, type RefObject } from 'react';
import type {
  OZAConstraints,
  RollAngleConstraint,
  SZAConstraints,
} from '_api/tasking/types';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import InfoCard from '_molecules/InfoCard/InfoCard';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Dialog } from 'react-aria-components';
import type { CloudCoverConstraint } from '../../../../_api/tasking/types';
import {
  Button,
  DialogTrigger,
  Popover,
  Icon,
  Tooltip,
  zIndexValues,
} from 'opencosmos-ui';

export type OpportunityConstraintsProps = {
  setSZAConstraints: (SZAConstraints: SZAConstraints) => void;
  SZAConstraints: SZAConstraints;
  rollAngleConstraint: RollAngleConstraint;
  setRollAngleConstraint: (rollAngleConstraint: RollAngleConstraint) => void;
  setSZAError: (isSSZAErrorZA: boolean) => void;
  setIsRollAngleError: (rollAngleError: boolean) => void;
  SZAError: boolean;
  hasRollAngleError: boolean;
  isSZA: boolean;
  setIsSZA: (isSZA: boolean) => void;
  setIsRollAngle: (isRollAngle: boolean) => void;
  isRollAngle: boolean;
  constraintsDescription: string;
  getNoOfActiveConstraints: () => number;
  disabled: boolean;
  parametersRef?: RefObject<HTMLDivElement>;
  isRequestTypeAutomated: boolean;
  setOZAConstraints: (OZAConstraints: OZAConstraints) => void;
  OZAConstraints: OZAConstraints;
  isOZA: boolean;
  setIsOZA: (isOZA: boolean) => void;
  isCloud: boolean;
  setIsCloud: (isCloud: boolean) => void;
  cloudConstraint: CloudCoverConstraint;
  setCloudConstraint: (cloudConstraint: CloudCoverConstraint) => void;
};

export const OpportunityConstraints = ({
  setSZAConstraints,
  SZAConstraints,
  rollAngleConstraint,
  setRollAngleConstraint,
  setSZAError,
  isSZA,
  setIsSZA,
  setIsRollAngle,
  isRollAngle,
  getNoOfActiveConstraints,
  constraintsDescription,
  disabled,
  parametersRef,
  isRequestTypeAutomated,
  isOZA,
  setIsOZA,
  OZAConstraints,
  setOZAConstraints,
  isCloud,
  setIsCloud,
  cloudConstraint,
  setCloudConstraint,
}: OpportunityConstraintsProps) => {
  const { sendInfo } = useAnalytics();

  useEffect(() => {
    if (SZAConstraints.Min > SZAConstraints.Max) {
      setSZAError(true);
    } else {
      setSZAError(false);
    }
  }, [SZAConstraints.Max, SZAConstraints.Min, setSZAError]);

  const handleCloudConstraintChange = (values: number[]) => {
    setCloudConstraint({
      ...cloudConstraint,
      min: values[0],
      max: values[1],
    });
  };

  const handleSZAConstraintChange = (values: number[]) => {
    setSZAConstraints({
      ...SZAConstraints,
      Min: values[0],
      Max: values[1],
    });
  };

  const handleOZAConstraintChange = (values: number[]) => {
    setOZAConstraints({
      ...OZAConstraints,
      Min: values[0],
      Max: values[1],
    });
  };

  const handleRollAngleConstraintChange = (values: number[]) => {
    setRollAngleConstraint({
      ...rollAngleConstraint,
      min: values[0],
      max: values[1],
    });
  };

  const getValues = () => {
    return {
      rollAngle: [rollAngleConstraint.min, rollAngleConstraint.max],
      sza: [SZAConstraints.Min, SZAConstraints.Max],
      oza: [OZAConstraints.Min, OZAConstraints.Max],
      cloud: [cloudConstraint.min, cloudConstraint.max],
    };
  };

  const areConstraintsApplied = getNoOfActiveConstraints() !== 0;

  const { translate } = useLocalisation();

  return (
    <div className="w-full relative">
      <DialogTrigger defaultOpen>
        <Button isDisabled={disabled} isMinimal isTransparent size="sm" fill>
          <div className="flex items-center gap-1">
            <div className="flex items-center gap-1">
              <span>Constraints</span>
              {areConstraintsApplied && (
                <span className="rounded-full bg-surface dark:bg-item-dark dark:text-item-dark-contrast text-xs  h-4 w-4 text-center border-[1px] border-item-contrast">
                  {getNoOfActiveConstraints()}
                </span>
              )}
            </div>
            <Tooltip content={constraintsDescription}>
              <Icon icon="Info" size={16} />
            </Tooltip>
          </div>
        </Button>
        <Popover
          placement="right top"
          style={{ zIndex: zIndexValues.popover }}
          triggerRef={parametersRef}
        >
          <Dialog>
            <div className="bg-item flex flex-col gap-2 p-1 dark:bg-item-dark dark:text-item-dark-contrast">
              <div className="p-1 border-b-2 border-b-contrast-inactive">
                Constraints
              </div>

              {isRequestTypeAutomated ? (
                <div className="flex-col w-full">
                  <div className="flex items-center w-full">
                    <div className="flex items-center w-full gap-4">
                      <InfoCard
                        showInfoOn="hover"
                        cardContent={
                          <div className="flex items-center w-full gap-4">
                            <Icon icon="Angle" />
                            <span>
                              {translate(
                                'datacosmos.tasking.new.constraints.oza'
                              )}
                            </span>
                          </div>
                        }
                        info={{
                          text: translate(
                            'datacosmos.tasking.new.constraints.ozaDescription'
                          ),
                          iconSize: 16,
                        }}
                        absoluteInfoPosition
                        onClick={() => {}}
                        className="hover:bg-transparent"
                      />
                    </div>

                    <Button
                      icon={isOZA ? 'Trash' : 'PlusSquare'}
                      isMinimal
                      size="base"
                      isTransparent
                      onPress={() => {
                        sendInfo({
                          type: 'OZA constraint toggle',
                          action: 'Toggle',
                          item: 'OZA constraint',
                          module: 'DataCosmos',
                        });

                        if (isOZA) {
                          setIsOZA(false);
                        } else {
                          setIsOZA(true);
                          setOZAConstraints({
                            ...OZAConstraints,
                            Active: true,
                            Min: 0,
                            Max: 180,
                          });
                        }
                      }}
                    />
                  </div>
                  {isOZA && (
                    <RangeSlider
                      minValue={0}
                      maxValue={180}
                      numberOfHandles={2}
                      onChange={(values) => {
                        handleOZAConstraintChange(values);
                      }}
                      showValuesAboveHandles
                      step={0.1}
                      value={getValues().oza}
                    />
                  )}
                </div>
              ) : (
                <div className="flex-col w-full">
                  <div className="flex items-center w-full">
                    <div className="flex items-center w-full gap-4">
                      <InfoCard
                        showInfoOn="hover"
                        cardContent={
                          <div className="flex items-center w-full gap-4">
                            <Icon icon="SatelliteRollAngle" />
                            <span>Roll angle</span>
                          </div>
                        }
                        info={{
                          text: translate(
                            'datacosmos.tasking.new.constraints.rollAngleDescription'
                          ),
                          iconSize: 16,
                        }}
                        absoluteInfoPosition
                        onClick={() => {}}
                        className="hover:bg-transparent"
                      />
                    </div>
                    <Button
                      icon={isRollAngle ? 'Trash' : 'PlusSquare'}
                      isMinimal
                      size="base"
                      isTransparent
                      onPress={() => {
                        sendInfo({
                          type: 'Roll angle constraint toggle',
                          action: 'Toggle',
                          item: 'Roll angle constraint',
                          module: 'DataCosmos',
                        });

                        if (isRollAngle) {
                          setIsRollAngle(false);
                        } else {
                          setIsRollAngle(true);
                          setRollAngleConstraint({
                            ...rollAngleConstraint,
                            min: -45,
                            max: 45,
                          });
                        }
                      }}
                    />
                  </div>
                  {isRollAngle && (
                    <RangeSlider
                      minValue={-45}
                      maxValue={45}
                      numberOfHandles={2}
                      onChange={(values) => {
                        handleRollAngleConstraintChange(values);
                      }}
                      showValuesAboveHandles
                      step={1}
                      value={getValues().rollAngle}
                      className="px-2"
                    />
                  )}
                </div>
              )}

              <div className="flex-col w-full">
                <div className="flex items-center w-full">
                  <div className="flex items-center w-full gap-4">
                    <InfoCard
                      showInfoOn="hover"
                      cardContent={
                        <div className="flex items-center w-full gap-4">
                          <Icon icon="SunAngle" />
                          <span>
                            {translate(
                              'datacosmos.tasking.new.constraints.sza'
                            )}
                          </span>
                        </div>
                      }
                      info={{
                        text: translate(
                          'datacosmos.tasking.new.constraints.szaDescription'
                        ),
                        iconSize: 16,
                      }}
                      absoluteInfoPosition
                      onClick={() => {}}
                      className="hover:bg-transparent"
                    />
                  </div>
                  <div className="flex items-center px-1">
                    <Tooltip
                      content={translate(
                        'datacosmos.tasking.new.constraints.szaDayButton'
                      )}
                    >
                      <Button
                        icon="Sun"
                        isMinimal
                        isTransparent
                        aria-label="set SZA constraint to day-time"
                        onPress={() => {
                          sendInfo({
                            type: 'SZA constraint set to day',
                            action: 'Set/Click',
                            item: 'SZA constraint',
                            module: 'DataCosmos',
                          });
                          setIsSZA(true);
                          setSZAConstraints({
                            ...SZAConstraints,
                            Active: true,
                            Min: 0,
                            Max: 90,
                          });
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="flex items-center px-1 pr-4">
                    <Tooltip
                      content={translate(
                        'datacosmos.tasking.new.constraints.szaNightButton'
                      )}
                    >
                      <Button
                        icon="Moon"
                        isMinimal
                        isTransparent
                        aria-label="set SZA constraint to night-time"
                        onPress={() => {
                          sendInfo({
                            type: 'SZA constraint set to night',
                            action: 'Set/Click',
                            item: 'SZA constraint',
                            module: 'DataCosmos',
                          });

                          setIsSZA(true);
                          setSZAConstraints({
                            ...SZAConstraints,
                            Active: true,
                            Min: 90,
                            Max: 180,
                          });
                        }}
                      />
                    </Tooltip>
                  </div>
                  <Button
                    icon={isSZA ? 'Trash' : 'PlusSquare'}
                    isMinimal
                    isTransparent
                    size="base"
                    onPress={() => {
                      sendInfo({
                        type: 'SZA constraint toggle',
                        action: 'Toggle',
                        item: 'SZA constraint',
                        module: 'DataCosmos',
                      });

                      if (isSZA) {
                        setIsSZA(false);
                      } else {
                        setIsSZA(true);
                        setSZAConstraints({
                          ...SZAConstraints,
                          Active: true,
                          Min: 0,
                          Max: 180,
                        });
                      }
                    }}
                  />
                </div>
                {isSZA && (
                  <RangeSlider
                    minValue={0}
                    maxValue={180}
                    numberOfHandles={2}
                    onChange={(values) => {
                      handleSZAConstraintChange(values);
                    }}
                    showValuesAboveHandles
                    step={0.1}
                    value={getValues().sza}
                    className="px-2"
                  />
                )}
              </div>

              <div className="flex-col w-full mb-2">
                <div className="flex items-center w-full">
                  <div className="flex items-center w-full gap-4">
                    <InfoCard
                      showInfoOn="hover"
                      cardContent={
                        <div className="flex items-center w-full gap-4">
                          <Icon icon="Cloud" />
                          <span>
                            {translate(
                              'datacosmos.tasking.new.constraints.cloud'
                            )}
                          </span>
                        </div>
                      }
                      info={{
                        text: translate(
                          'datacosmos.tasking.new.constraints.cloudDescription'
                        ),
                        iconSize: 16,
                      }}
                      absoluteInfoPosition
                      onClick={() => {}}
                      className="hover:bg-transparent"
                    />
                  </div>
                  <Button
                    icon={isCloud ? 'Trash' : 'PlusSquare'}
                    isMinimal
                    isTransparent
                    size="base"
                    onPress={() => {
                      sendInfo({
                        type: 'Cloud constraint toggle',
                        action: 'Toggle',
                        item: 'Cloud constraint',
                        module: 'DataCosmos',
                      });

                      if (isCloud) {
                        setIsCloud(false);
                      } else {
                        setIsCloud(true);
                        setCloudConstraint({
                          ...cloudConstraint,
                          min: 0,
                          max: 30,
                        });
                      }
                    }}
                  />
                </div>
                {isCloud && (
                  <RangeSlider
                    minValue={0}
                    maxValue={100}
                    numberOfHandles={2}
                    onChange={(values) => {
                      handleCloudConstraintChange(values);
                    }}
                    showValuesAboveHandles
                    step={1}
                    value={getValues().cloud}
                    className="px-2"
                  />
                )}
              </div>
            </div>
          </Dialog>
        </Popover>
      </DialogTrigger>
    </div>
  );
};
