import { combineReducers } from 'redux';

import loading from './loading';
import user from './user';
import groups from './groupSatelliteBalls';
import missions from './missions';

const rootReducer = combineReducers({
  loading,
  user,
  groups,
  missions,
});
export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
