import { useEffect, useRef } from 'react';
import type { TaskingRequest } from '_api/tasking/types';
import type { Activity } from '_api/activities/service';
import classNames from 'classnames';
import { clientTranslate } from 'utils/hooks/useLocalisation';
import { Button } from 'opencosmos-ui';
import { useActivitiesMap } from 'pages/shared/hooks/useActivitiesMap';

type ActivitiesMapProps = {
  activity: Activity | Activity[] | undefined;
  request: TaskingRequest | undefined;
  mapElements?: React.ReactElement | React.ReactElement[];
  aspectRatio?: '16/9' | '1/1' | 'auto';
  autoHeight?: boolean;
};

export const ActivitiesMap = ({
  request,
  activity,
  aspectRatio,
  mapElements,
  autoHeight,
}: ActivitiesMapProps) => {
  const container = useRef<HTMLDivElement>(null);

  const {
    initMap,
    mapRef,
    addStacItemThumbnailToMap,
    removeStacItemThumbnailFromMap,
    isThumbnailShown,
    shouldShowThumbnailButton,
    focusThumbnail,
  } = useActivitiesMap(container, activity, request);

  useEffect(() => {
    initMap();

    return () => {
      mapRef.current?.remove();
      mapRef.current = undefined;
    };
  }, [initMap, activity, request, mapRef]);

  return (
    <div className="relative">
      {mapElements && (
        <div className="absolute bottom-1 right-1 z-20">{mapElements}</div>
      )}

      <div
        className={classNames(
          'absolute right-20 top-[60px] bg-[#ffffffb3] w-[220px] m-[10px] px-[10px] py-[5px] z-[2]',
          'dark:bg-surface-dark p-2 dark:text-item-dark-contrast z-30 !right-1'
        )}
        style={{ right: '5rem' }}
      >
        <div className={'grid grid-cols-[0.5fr_2fr] items-center'}>
          <div
            className={
              'w-[25px] h-[25px] my-[5px] bg-[#0000ff80] border-2 border-[#0000ff]'
            }
          />
          <span>{clientTranslate('datacosmos.tasking.legend.aoi')}</span>
        </div>

        <div className={'grid grid-cols-[0.5fr_2fr] items-center'}>
          <div className="w-[25px] h-[25px] my-[5px] bg-[#ffcc5580] border-2 border-[#ffcc55] " />
          <span>
            {clientTranslate('datacosmos.tasking.legend.currentSwath')}
          </span>
        </div>

        <div className={'grid grid-cols-[0.5fr_2fr] items-center'}>
          <div
            className={
              'w-[25px] h-[25px] my-[5px] bg-[#80808080] border-2 border-[#59595980]'
            }
          />
          <span>{clientTranslate('datacosmos.tasking.legend.target')}</span>
        </div>
      </div>

      {shouldShowThumbnailButton && (
        <Button
          icon={isThumbnailShown ? 'EyeCrossed' : 'Eye'}
          className={classNames('absolute top-36 z-[100]', {
            'right-12': isThumbnailShown,
            'right-6': !isThumbnailShown,
          })}
          onPress={async () => {
            if (isThumbnailShown) {
              removeStacItemThumbnailFromMap();
              return;
            }

            await addStacItemThumbnailToMap();
          }}
        >
          {isThumbnailShown ? 'Remove thumbnail' : 'Show thumbnail'}
        </Button>
      )}

      {isThumbnailShown && (
        <Button
          icon="Center"
          className="absolute right-4 top-36 z-[100] h-[30px] w-[30px]"
          onPress={() => focusThumbnail()}
        />
      )}

      <div
        ref={container}
        id="activities-map"
        className={classNames('w-full z-10', {
          'aspect-video': aspectRatio === '16/9',
          'aspect-square': aspectRatio === '1/1',
          'aspect-auto': aspectRatio === 'auto',
          'h-96': !autoHeight,
        })}
      />
    </div>
  );
};
